import React from "react";
import { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";

import { TopNavigation, BottomNavigation } from "./components/Navigation";
import FileChooser from "./components/FileChooser";
import DataEditor from "./components/DataEditor";

const App = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        //console.log("data", data);
    }, [data]);

    return (
        <>
            <TopNavigation />
            <div>&nbsp;</div>
            <Container style={{ marginTop: "4em", minHeight: "82.5vh" }}>
                <FileChooser onFileLoaded={ setData } />
                <DataEditor data={ data } />
            </Container>
            <BottomNavigation />
        </>
    );
}

export default App;