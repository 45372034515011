import { useEffect, useState } from "react";
import { Grid, Input } from "semantic-ui-react";

const DataController = ({ pressAltOffset, onPressAltOffsetChange, data }) => {
    const [takeoffAlt, setTakeoffAlt] = useState(10);
    const [landingAlt, setLandingAlt] = useState(10);

    useEffect(() => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].type === "B" && data[i].parsedData !== null) {
                setTakeoffAlt(data[i].parsedData.altPress);
                break;
            }
        }

        for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].type === "B" && data[i].parsedData !== null) {
                setLandingAlt(data[i].parsedData.altPress);
                break;
            }
        }

    }, [data]);

    return(
        <Grid divided='vertically'>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <Input label="Adjust Pressure Altitude" type="number" value={ pressAltOffset } onChange={ onPressAltOffsetChange } style={{ width: "100%" }} />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column>
                    <Input label="Takeoff Altitude" type="number" value={ takeoffAlt + pressAltOffset } disabled />
                </Grid.Column>
                <Grid.Column>
                    <Input label="Landing Altitude" type="number" value={ landingAlt + pressAltOffset } disabled />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default DataController;