import { Icon, Table } from "semantic-ui-react";

const DataTableViewer = ({ data, pressAltOffset }) => {
    const renderRows = () => {
        return data.filter(record => record.type === "B" && record.parsedData !== null).map(record => {
            return (
                <Table.Row key={ record.parsedData.utc }>
                    <Table.Cell>{ record.parsedData.utc }</Table.Cell>
                    <Table.Cell>{ record.parsedData.lat }</Table.Cell>
                    <Table.Cell>{ record.parsedData.lng }</Table.Cell>
                    <Table.Cell>{ record.parsedData.altPress } <Icon name="arrow right" /> { record.parsedData.altPress + pressAltOffset }</Table.Cell>
                    <Table.Cell>{ record.parsedData.altGps }</Table.Cell>
                </Table.Row>
            );
        });
    }

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell>Latitude</Table.HeaderCell>
                    <Table.HeaderCell>Longitude</Table.HeaderCell>
                    <Table.HeaderCell>Pressure Altitude</Table.HeaderCell>
                    <Table.HeaderCell>GPS Altitude</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                { renderRows() }
            </Table.Body>
        </Table>
    );
}

export default DataTableViewer;