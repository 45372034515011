import { Button, Icon } from "semantic-ui-react";
import IgcProcessor from "../igcprocessor/IgcProcessor";

const DataDownloader = ({ data, pressAltOffset }) => {
    let downloadAnchor;

    const filename = () => {
        const now = new Date();
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + " " + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds() + " patched.igc";
    }

    const downloadIgcFile = () => {
        if (downloadAnchor) {
            const patchedData = JSON.parse(JSON.stringify(data));

            for (const record of patchedData) {
                if (record.type === "B" && record.parsedData !== null) {
                    record.parsedData.altPress += pressAltOffset;
                }
            }

            downloadAnchor.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(IgcProcessor.serialize(patchedData)));
            downloadAnchor.setAttribute("download", filename());
            downloadAnchor.click();
        }
    };

    return (
        <>
            <a href="/#" ref={ a => downloadAnchor = a } style={{ display: "none" }}>Clicked Programatically</a>
            <Button positive style={{ width: "100%" }} onClick={ downloadIgcFile } ><Icon name="cloud download" /> Download Patched IGC File</Button>
        </>
    );
}

export default DataDownloader;