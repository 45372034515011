import { useState, useEffect } from "react";
import { Accordion, Segment } from "semantic-ui-react";
import IgcProcessor from "../igcprocessor/IgcProcessor";

import DataController from "./DataController";
import DataDownloader from "./DataDownloader";
import DataGraphViewer from "./DataGraphViewer";
import DataTableViewer from "./DataTableViewer";
import SectionHeader from "./SectionHeader";

const DataEditor = ({ data }) => {
    const [pressAltOffset, setPressAltOffset] = useState(0);
    const [parsedData, setParsedData] = useState([]);
    const [accordionState, setAccordionState] = useState({editData: true, graphData: true, tableData: false, rawData: false});

    useEffect(() => {
        if (data) {
            try {
                const pData = IgcProcessor.parse(data);

                // reset state when source data changes
                setPressAltOffset(0);
                setParsedData(pData);
            }
            catch (e) {
                console.error(e);
                setParsedData({});
            }
        }
    }, [data]);

    if (!parsedData || Object.keys(parsedData).length === 0) {
        return <></>;
    }

    return (
        <>
            <Segment>
                <Accordion>
                    <Accordion.Title active={accordionState.editData} onClick={ () => setAccordionState({...accordionState, editData: !accordionState.editData }) }>
                        <SectionHeader title="Edit Data" icon="edit" />
                    </Accordion.Title>
                    <Accordion.Content active={accordionState.editData}>
                        <DataController pressAltOffset={ pressAltOffset } onPressAltOffsetChange={ e => setPressAltOffset(Number.parseInt(e.target.value)) } data={ parsedData } />
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment>
                <Accordion>
                    <Accordion.Title active={accordionState.graphData} onClick={ () => setAccordionState({...accordionState, graphData: !accordionState.graphData }) }>
                        <SectionHeader title="Graph Data" icon="map" />
                    </Accordion.Title>
                    <Accordion.Content active={accordionState.graphData}>
                        <DataGraphViewer data={ parsedData } pressAltOffset = { pressAltOffset } />
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment>
                <Accordion>
                    <Accordion.Title active={accordionState.tableData} onClick={ () => setAccordionState({...accordionState, tableData: !accordionState.tableData }) }>
                        <SectionHeader title="Tabular Data" icon="table" />
                    </Accordion.Title>
                    <Accordion.Content active={accordionState.tableData}>
                        <DataTableViewer data={ parsedData } pressAltOffset = { pressAltOffset } />
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment>
                <Accordion>
                    <Accordion.Title active={accordionState.rawData} onClick={ () => setAccordionState({...accordionState, rawData: !accordionState.rawData }) }>
                        <SectionHeader title="Raw Data" icon="database" />
                    </Accordion.Title>
                    <Accordion.Content active={accordionState.rawData}>
                        <pre>{ JSON.stringify(parsedData, null, 4) }</pre>
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <DataDownloader data={ parsedData } pressAltOffset={ pressAltOffset } />
        </>
    );
};

export default DataEditor