import { Header, Icon } from "semantic-ui-react";

const SectionHeader = ({ title, icon }) => {
    return (
        <Header>
            <Header.Content><Icon name='dropdown' /><Icon name={ icon } />{ title }</Header.Content>
        </Header>
    );
};

export default SectionHeader;