/**
 * data = [
 *     { type: "X", rawData: "...", parsedData: { time, lat, lng, pressAlt, gpsAlt } },
 *     ...
 * ]
 */

const NEW_LINE = "\r\n";

class IgcProcessor {
    static makeEntry(type, rawData, parsedData) {
        return { type: type, rawData: rawData, parsedData: parsedData }
    }

    static parse(igcString) {
        const lines = igcString.split(NEW_LINE);

        const res = lines.map(line => {
            if (line.length === 0 || (line[0] !== "A" && line[0] !== "B" && line[0] !== "G")) return IgcProcessor.makeEntry(line[0], line, null);

            if (line[0] === "A" || line[0] === "G") {
                // invalidate security
                const record = line[0] + "0".repeat(line.length - 1);
                return IgcProcessor.makeEntry(line[0], record, null);
            }

            if (line[0] === "B" && line.length !== 35) {
                console.warn("Could not parse line ", line, "/ Reason: Invalid length of", line.length, "Bytes.");
                return IgcProcessor.makeEntry(line[0], line, null);
            }

            const parsedData = {};

            parsedData.type = line.substring(0, 1);
            parsedData.utc = line.substring(1, 7);
            parsedData.lat = line.substring(7, 15);
            parsedData.lng = line.substring(15, 24);
            parsedData.fix = line.substring(24, 25);
            parsedData.altPress = Number.parseInt(line.substring(25, 30));
            parsedData.altGps = Number.parseInt(line.substring(30, 35));

            if (isNaN(parsedData.altPress || isNaN(parsedData.altGps))) {
                console.warn("Could not parse line ", line, "/ Reason: Could not parse altitude values!");
                return IgcProcessor.makeEntry(line[0], line, null);
            }

            return IgcProcessor.makeEntry(line[0], line, parsedData);
        });

        return res;
    }

    static serialize(igcData) {
        const resArr = igcData.map(record => {
            if (record.type === "B" && record.parsedData !== null) {
                return record.parsedData.type
                    + record.parsedData.utc
                    + record.parsedData.lat
                    + record.parsedData.lng
                    + record.parsedData.fix
                    + String(record.parsedData.altPress).padStart(5, "0")
                    + String(record.parsedData.altGps).padStart(5, "0");
            }
            else {
                return record.rawData;
            }
        });

        return resArr.join(NEW_LINE);
    }
}

export default IgcProcessor; 