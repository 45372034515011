import { useState } from "react";
import { Input } from "semantic-ui-react";

const FileChooser = ({ onFileLoaded }) => {
    const [filename, setFilename] = useState("");

    const onFileChange = (e) => {
        setFilename(e.target.value);

        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.onload = () => {
                onFileLoaded(reader.result);
            };

            reader.readAsText(file, "UTF-8");
        }
    };

    return <Input type="file" accept="" icon="cloud upload" style={{ width: "100%" }} onChange={ onFileChange } value={ filename } />;
}

export default FileChooser;