import { useEffect, useState } from "react";

const DataGraphViewer = ({ data, pressAltOffset }) => {
    const [elevationData, setElevationData] = useState([]);

    const fetchElevationData = async () => {
        try {
            let headers = new Headers();
        
            //headers.append('Content-Type', 'application/json');
            //headers.append('Accept', 'application/json');
            headers.append('Origin','https://igctool.chris-hoffmann.ch');

            const res = await fetch("https://api.opentopodata.org/v1/aster30m?locations=57.688709,11.976404", {
                mode: 'cors',
                method: 'GET',
                headers: headers
            });
            const jsonRes = await res.json();
            console.log(jsonRes);
        }
        catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchElevationData();
    }, [data]);

    return (
        <></>
    );
};

export default DataGraphViewer;