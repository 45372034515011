import { Menu, Container, Image, Icon } from "semantic-ui-react";

const TopNavigation = () => {
    return (
        <Menu fixed='top' inverted>
            <Container>
                <Menu.Item as='a' header>
                    <Image size='mini' src='/logo192.png' style={{ marginRight: '1.5em' }} />
                    IGC Tool
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item as='a' href="#" target="_blank"><Icon name="github" />GitHub</Menu.Item>
                    <Menu.Item as='a' href="#" target="_blank"><Icon name="like" />Sponsor</Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    );
};

const BottomNavigation = () => {
    return (
        <Menu secondary size="mini">
            <Menu.Menu style={{ margin: "0 auto" }}>
                <Menu.Item as='a' href="#" target="_blank">Legal Notice</Menu.Item>
                <Menu.Item as='a' href="#" target="_blank">Privacy Policy</Menu.Item>
            </Menu.Menu>
        </Menu>
    );
};

export { TopNavigation, BottomNavigation };
